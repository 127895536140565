import React from 'react'
import MetaData from '../../components/MetaData'
import PageWrapper from '../../components/PageWrapper'
import Hero from '../../sections/common/Hero'
import { Container, Col, Row } from 'react-bootstrap'
import { Section, Text, Title, Box } from '../../components/Core'
import * as styles from '../../sections/landing1/Home.module.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SocketClusterIcon from '../../assets/image/svg/socket-cluster-io.svg'

const TestimonialCardExternal = ({
  icon,
  title,
  linkText,
  URL,
  children,
  ...rest
}) => {
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <Box
      border="1px solid"
      borderRadius={10}
      borderColor="border"
      bg={userDarkMode ? 'dark' : 'white'}
      py="25px"
      px="30px"
      mb={4}
      {...rest}
    >
      <img
        width="30"
        height="auto"
        className="mb-3"
        src={icon}
        alt=""
        role="presentation"
      />
      <Title color={userDarkMode ? 'light' : 'dark'} variant="card">
        {title}
      </Title>
      <Text
        color={userDarkMode ? 'light' : 'dark'}
        variant="small"
        className="mb-4"
      >
        {children}
      </Text>
      <a
        color={userDarkMode ? 'primaryLight' : 'primary'}
        className={styles.aTagButton}
        target="_blank"
        rel="noopener noreferrer"
        href={URL}
      >
        {linkText}
      </a>
    </Box>
  )
}

const Partnerships = () => {
  const { t } = useTranslation()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('partnershipsPage.heroTitle')}
        ></Hero>
        <Section
          pt={[3, 4, 4, 5]}
          bg={userDarkMode ? 'black' : 'bg'}
          className="position-relative"
        >
          <Container>
            <div className="align-items-center">
              <Row>
                <Col lg="6">
                  <TestimonialCardExternal
                    icon={SocketClusterIcon}
                    title={t('testimonialsPage.socketClusterTitle')}
                    linkText={t('partnershipsPage.visit') + ` socketcluster.io`}
                    URL="https://socketcluster.io/"
                  >
                    {t('testimonialsPage.socketClusterCard')}
                    <br />
                    <strong>{t('testimonialsPage.robsComment')}</strong> —Robert
                    Borden, Coinigy CEO
                  </TestimonialCardExternal>
                </Col>
                <Col lg="6"></Col>
              </Row>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Partnerships
